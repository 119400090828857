/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useAppServices } from "hook/services";
import { useEffect, useState } from "react";
import EditModal from "../../EditModal";
import { useAgencyInfo } from "context/agency";
import { Roles } from "enums/user";
import demoImage from "assets/images/image.jpg";

export default function DataTableData() {
  const AppService = useAppServices();
  const [List, setList] = useState([]);
  const [loader, setloader] = useState(true);
  const [agency] = useAgencyInfo();

  const getCategories = async () => {
    const { response } = await AppService.themes.Get({
      query: `agency_id=${agency._id}`,
      toaster: false,
    });
    if (response) {
      setList(response.data);
    } else {
      setList([]);
    }
    setloader(false);
  };
  const onLoad = () => {
    getCategories();
  };
  const handleDelete = async (id) => {
    console.log(id, "del id");
    const { response } = await AppService.themes.Delete({ 
      query: `_id=${id}`,
     });
    if (response) onLoad();
  };

  useEffect(onLoad, []);
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: "Image", accessor: "image" },
        { Header: "Name", accessor: "name" },
        { Header: "Status", accessor: "status" },
        
        { Header: "Action", accessor: "action" },
      ],

      rows: List.map((data) => ({
        image: (
          <MDBox
            component="img"
            src={data.image || demoImage}
            alt={data.name}
            sx={{ width: 150, height: 150, borderRadius: 1 }}
          />
        ),
        name: data.name,
        status: data.status,
        action: (
          <MDBox>
            <EditModal data={data} handleRefresh={onLoad} />
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: 2 }}
              onClick={() => handleDelete(data._id)}
            >
              Delete
            </MDButton>
          </MDBox>
        ),
      })),
    },
  };
}
