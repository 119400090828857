import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useAppServices, useUploadImage } from "hook/services";
import { useUserInfo } from "context/user";
import FormField from "components/FormField";
import MDInput from "components/MDInput";
import Multiselect from "./components/Multiselect";
import { useAgencyInfo } from "context/agency";

const CreateModal = ({ products, handleRefresh }) => {
  const [agency] = useAgencyInfo();

  const uploadImage = useUploadImage();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const AppService = useAppServices();
  const [user] = useUserInfo();
  var axios = require("axios");

  const handlemodal = () => {
    setOpenAddProduct(true);
  };
 
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      // setProcessing(true);
      let image_response = null;

      if (e.target.image.files.length > 0) {
        image_response = await uploadImage({
          file: e.target.image.files[0],
          desiredPath: `app/themes/${agency._id}`,
        });

        if (!image_response.response) {
          return;
        } else {
          image_response = image_response.response.data;
        }
      }

      const payload = {
        name: e.target.name.value,
        image: image_response,
        agency_id: agency._id,
        theme_type: "supperadmin",
      };
      
      console.log(payload);
      const { response } = await AppService.themes.create({payload});
      
      if (response) {
        setProcessing(false);
        onClose();
        handleRefresh();
      }
    };
    return (
      <MDModal open={open} onClose={onClose} width={440}>
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <MDTypography variant="h5" mb={2}>
              Create Theme
            </MDTypography>

            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: "14px", fontWeight: "500" }}
                mb={1}
              >
                Name
              </MDTypography>
              <MDInput label="Name" placeholder="Name" name="name" fullWidth />
            </MDBox>

            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: "14px", fontWeight: "500" }}
                mb={1}
              >
                Preview
              </MDTypography>
              <MDInput
                placeholder="image"
                name="image"
                fullWidth
                type="file"
              />
            </MDBox>

            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton color="white" variant="contained" onClick={handlemodal}>
        Create
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default CreateModal;
