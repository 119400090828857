import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import MDInput from 'components/MDInput'
import { useAgencyInfo } from 'context/agency'

const CreateModal = ({ products, handleRefresh }) => {
  const [agency] = useAgencyInfo()

  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()

  const handlemodal = () => {
    setOpenAddProduct(true)
  }

  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault()
      // setProcessing(true);

      const payload = {
        name: e.target.name.value,
        agency_id: agency._id,
      }

      console.log(payload)
      const { response } = await AppService.service_types.create({ payload })

      if (response) {
        setProcessing(false)
        onClose()
        handleRefresh()
      }
    }
    return (
      <MDModal open={open} onClose={onClose} width={440}>
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <MDTypography variant="h5" mb={2}>
              Create Type
            </MDTypography>

            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Name
              </MDTypography>
              <MDInput label="Name" placeholder="Name" name="name" fullWidth />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton color="white" variant="contained" onClick={handlemodal}>
        Create
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default CreateModal
