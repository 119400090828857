import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import MDInput from 'components/MDInput'
const EditModal = ({ data, handleRefresh }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()

  const handlemodal = () => {
    setOpenAddProduct(true)
  }

  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault()
      // setProcessing(true);
      const payload = {
        _id: data._id,
        name: e.target.name.value,
      }

      const { response } = await AppService.service_types.update({ payload })

      if (response) {
        setProcessing(false)
        onClose()
        handleRefresh()
      }
    }

    return (
      <MDModal open={open} onClose={onClose} width={440}>
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <MDTypography variant="h5" mb={2}>
              Edit Type
            </MDTypography>

            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Name
              </MDTypography>
              <MDInput
                label="Name"
                placeholder="Name"
                name="name"
                fullWidth
                defaultValue={data.name}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                Edit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton color="info" variant="contained" onClick={handlemodal}>
        Edit
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} data={data} />
    </>
  )
}

export default EditModal
