import { Environment } from '../enums'

const env = {
  API_URL: 'http://localhost:8082/api',
  // API_URL: 'https://api.directorystagingsite.com/api',
  GHL: {
    CLIENT_ID: '6740679868dc6b6937c96d1b-m4u1rusv',
    CLIENT_SECRET: '844e948d-cc20-4094-a252-e0addaa56f66',
    Location_CLIENT_ID: '65fb2825df9b3553efed3588-lu04mtae',
    Location_CLIENT_SECRET: '1546edc3-a2fc-4ec9-aaa7-5b8cd8515f85',
    Location_SCOPE:
      'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.write invoices/schedule.readonly invoices/template.readonly invoices/template.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write opportunities.readonly opportunities.write users.readonly users.write workflows.readonly surveys.readonly',
    SCOPE:
      'oauth.readonly oauth.write saas/location.write saas/location.read saas/company.write saas/company.read contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    APPSCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly snapshots.readonly locations.write oauth.readonly oauth.write calendars.readonly calendars.write calendars/groups.readonly calendars/groups.write calendars/events.readonly calendars/events.write medias.readonly medias.write',
    REDIRECT: {
      Location_ASSOCIATE: 'https://maybesuper.fixmyonline.com/integrations/location',
      // ASSOCIATE: 'https://maybesuper.fixmyonline.com/integrations/agency',
      ASSOCIATE: 'https://admin.directorystagingsite.com/integrations/agency',
    },
  },
}

if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = 'https://api.directorystagingsite.com/api'
  env.GHL.REDIRECT.Location_ASSOCIATE =
    'https://admin.directorystagingsite.com/integrations/location'
}

if (process.env.REACT_APP_ENV === Environment.BETA) {
  env.API_URL = 'https://api.directorystagingsite.com/api'
  env.GHL.REDIRECT.Location_ASSOCIATE =
    'https://admin.directorystagingsite.com/integrations/location'
}

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = 'https://api.directorystagingsite.com/api'
  env.GHL.REDIRECT.Location_ASSOCIATE =
    'https://admin.directorystagingsite.com/integrations/location'
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://api.directorystagingsite.com/api'
  env.GHL.REDIRECT.Location_ASSOCIATE =
    'https://admin.directorystagingsite.com/integrations/location'
}

export default env
